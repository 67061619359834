import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { getWindow } from 'ssr-window';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-header',
    imports: [FontAwesomeModule, TranslateModule],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent {
  appleIcon = faApple;
  androidIcon = faAndroid;
  desktopIcon = faDesktop;

  lang = environment.lang === 'de' ? 'de/' : '';

  window = getWindow();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    // on scroll add class to nav
    this.window.addEventListener('scroll', () => {
      const nav = this.document.querySelector('nav');
      nav?.classList.toggle('bg-black', this.window.scrollY > 50);
    });
  }
}
