import {
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { Countdown } from "../../model/countdown.modal";
import { CountdownService } from "../../_services/countdown.service";
import { Router } from "@angular/router";
import {
  DatePipe,
  DecimalPipe,
  DOCUMENT,
  isPlatformBrowser,
  NgClass,
} from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import {
  Result,
  UnsplashData,
  UnsplashService,
} from "../../_services/unsplash.service";

@Component({
  selector: "app-add-modal",
  imports: [TranslateModule, FormsModule, DatePipe, NgClass, DecimalPipe],
  templateUrl: "./add-modal.component.html",
  styleUrl: "./add-modal.component.scss",
})
export class AddModalComponent {
  @ViewChild("newCountdown") newCountdown: ElementRef | undefined;
  newCountdownModal: bootstrap.Modal | undefined;

  @ViewChild("errorToast") errorToast: ElementRef | undefined;

  countdown: Countdown = {};

  currentYear = new Date().getFullYear();

  bootstrap: any;

  imageType: "default" | "unsplash" = "default";
  public searchTerm = "Urlaub";
  page = 1;
  maxPageSize = 1;
  public selectedImageId?: string;

  public unsplashData?: UnsplashData;

  constructor(
    private countdownService: CountdownService,
    private unsplash: UnsplashService,
    private router: Router,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (!this.countdownService.countdown().init) {
      this.countdown = this.countdownService.countdown();
    } else {
      this.countdown = {
        date: new Date(),
        time: new Date().toLocaleTimeString().slice(0, 5),
        init: false,
        image: 1,
      };
    }
  }

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.bootstrap = await import("bootstrap");
    }

    this.loadUnsplash();
  }

  saveModal() {
    if (!this.countdown.title || !this.countdown.date || !this.countdown.time) {
      this.showErrorToast();
      return;
    }
    this.findCheckedRadioInput();
    const date = new Date(this.countdown.date);
    this.countdown.date = date;
    this.countdownService.createCountdown(this.countdown).then((res) => {
      if (res.shortUrl) {
        this.closeModal();
        this.router.navigate([`/${res.shortUrl}`]);
      }
    });
  }

  findCheckedRadioInput() {
    const radioInputs = this.document.querySelectorAll(
      'input[type="radio"]:checked'
    );
    if (radioInputs.length > 0) {
      const radioInput = radioInputs[0] as HTMLInputElement;
      this.countdown.image = parseInt(radioInput.value);
      this.countdown.file = undefined;
      this.selectedImageId = undefined;
    }
  }

  showErrorToast() {
    if (this.errorToast) {
      const errorToast = this.bootstrap.Toast.getOrCreateInstance(
        this.errorToast.nativeElement
      );
      errorToast.show();
    } else {
      console.error("this.errorToast is undefined");
    }
  }

  onDateChange(event: any) {
    this.countdown.date = event;
  }

  closeModal() {
    if (this.newCountdownModal) {
      this.newCountdownModal.hide();
    } else {
      console.error("this.newCountdownModal is undefined");
    }
  }

  // Unsplash API
  loadUnsplash() {
    const browserLang = this.translate.getBrowserLang() ?? "en";
    if (RegExp(/de/).exec(browserLang)) {
      this.searchTerm = "Urlaub";
    } else {
      this.searchTerm = "Vacation";
    }

    this.unsplash
      .getPhotos(this.page, this.searchTerm)
      .then((promiseReturn: any) => {
        promiseReturn.subscribe((data: UnsplashData) => {
          this.unsplashData = data;
          this.maxPageSize = data.total_pages;
        });
      });
  }

  nextPage() {
    if (this.maxPageSize > this.page) {
      this.page++;
    }
    this.unsplash
      .getPhotos(this.page, this.searchTerm)
      .then((promiseReturn: any) => {
        promiseReturn.subscribe((data: UnsplashData) => {
          console.log(data);
          this.unsplashData = data;
        });
      });
  }

  previousPage() {
    if (this.page > 1) {
      this.page--;
    }
    this.unsplash
      .getPhotos(this.page, this.searchTerm)
      .then((promiseReturn: any) => {
        promiseReturn.subscribe((data: UnsplashData) => {
          this.unsplashData = data;
        });
      });
  }

  search() {
    this.page = 1;
    delete this.unsplashData;
    if (this.searchTerm.length > 3) {
      setTimeout(() => {
        this.unsplash
          .getPhotos(this.page, this.searchTerm)
          .then((promiseReturn: any) => {
            promiseReturn.subscribe((data: UnsplashData) => {
              this.unsplashData = data;
            });
          });
      }, 500);
    }
  }

  selectImage(image: Result) {
    this.countdown.image = 0;
    this.selectedImageId = image.id;
    if (image && image.id) {
      this.unsplash
        .getFileImageFromURL(image.urls.regular, image.id)
        .subscribe((file: File) => {
          this.countdown.file = file;
        });
    }
  }
}
