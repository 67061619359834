<header
  [ngClass]="['image' + countdown().image, deviceInfo.deviceType]"
  [ngStyle]="
    countdown().image === 0 && countdown().imagePath
      ? {
          'background-image':
            'url(' +
            'https://api.urlaubscountdown.de/uploads/' +
            countdown().imagePath +
            ')'
        }
      : {}
  "
>
  <div class="blur">
    <div class="container-fluid">
      <div class="row head-body">
        <div class="col-md-12 col-xs-12 head-items">
          <h1>{{ countdown().title }}</h1>
          @if(isPast){
          <h2>
            <span>{{ "webapp.countdown.isPast" | translate }}</span>
          </h2>
          <h3>
            {{ countdown().date | date : "dd. MMMM yyyy" }} -
            {{ countdown().time }}
          </h3>
          } @else if(countdown().counter! && !isPast){
          <div id="clock" class="row">
            @if(countdown().counter!.years && countdown().counter!.years! > 0) {
            <div class="col-3 countdown-col">
              <p>{{ countdown().counter!.years }}</p>
              <small>
                {{
                  countdown().counter!.years === 1
                    ? ("webapp.countdown.year" | translate)
                    : ("webapp.countdown.years" | translate)
                }}
              </small>
            </div>
            } @if(countdown().counter!.months && countdown().counter!.months! >
            0){
            <div class="col-3 countdown-col">
              <p>{{ countdown().counter!.months }}</p>
              <small>
                {{
                  countdown().counter!.months === 1
                    ? ("webapp.countdown.month" | translate)
                    : ("webapp.countdown.months" | translate)
                }}
              </small>
            </div>
            } @if (countdown().counter!.weeks && countdown().counter!.weeks! >
            0) {
            <div class="col-3 countdown-col">
              <p>{{ countdown().counter!.weeks }}</p>
              <small>
                {{
                  countdown().counter!.weeks === 1
                    ? ("webapp.countdown.week" | translate)
                    : ("webapp.countdown.weeks" | translate)
                }}
              </small>
            </div>
            } @if(countdown().counter!.days && countdown().counter!.days! > 0){
            <div class="col-3 countdown-col">
              <p>{{ countdown().counter!.days }}</p>
              <small>
                {{
                  countdown().counter!.days === 1
                    ? ("webapp.countdown.day" | translate)
                    : ("webapp.countdown.days" | translate)
                }}
              </small>
            </div>
            } @if(countdown().counter!.hours && countdown().counter!.hours! > 0)
            {
            <div class="col-3 countdown-col">
              <p>{{ countdown().counter!.hours }}</p>
              <small>
                {{
                  countdown().counter!.hours === 1
                    ? ("webapp.countdown.hour" | translate)
                    : ("webapp.countdown.hours" | translate)
                }}
              </small>
            </div>
            } @if(countdown().counter!.minutes && countdown().counter!.minutes!
            > 0) {
            <div class="col-3 countdown-col">
              <p>{{ countdown().counter!.minutes }}</p>
              <small>
                {{
                  countdown().counter!.minutes === 1
                    ? ("webapp.countdown.minute" | translate)
                    : ("webapp.countdown.minutes" | translate)
                }}
              </small>
            </div>
            } @if(countdown().counter!.seconds && countdown().counter!.seconds!
            > 0) {
            <div class="col-3 countdown-col">
              <p>{{ countdown().counter!.seconds }}</p>
              <small>
                {{
                  countdown().counter!.seconds === 1
                    ? ("webapp.countdown.second" | translate)
                    : ("webapp.countdown.seconds" | translate)
                }}
              </small>
            </div>
            }
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</header>
