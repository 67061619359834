import {
  ChangeDetectorRef,
  Component,
  inject,
  Inject,
  OnInit,
  PLATFORM_ID,
  signal,
  WritableSignal,
} from "@angular/core";
import { Countdown } from "../../model/countdown.modal";
import { CountdownService } from "../../_services/countdown.service";
import { DatePipe, isPlatformBrowser, NgClass, NgStyle } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-countdown",
  imports: [NgClass, DatePipe, TranslateModule, NgStyle],
  templateUrl: "./countdown.component.html",
  styleUrl: "./countdown.component.scss",
})
export class CountdownComponent implements OnInit {
  countdown: WritableSignal<Countdown> = signal({});
  isPast = false;

  deviceService = inject(DeviceDetectorService);
  deviceInfo = this.deviceService.getDeviceInfo();

  constructor(
    private countdownService: CountdownService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.countdown = this.countdownService.countdown;
  }

  ngOnInit(): void {
    this.startCountdown();
  }

  startCountdown() {
    this.runCountdown();
    if (isPlatformBrowser(this.platformId)) {
      setInterval(() => {
        this.runCountdown();
      }, 1000);
    }
  }

  runCountdown() {
    this.countdown = this.countdownService.countdown;
    if (!this.countdown().date || !this.countdown().time) {
      return;
    }
    this.countdown.update((value) => {
      value.date = new Date(value.date!.toString());
      return value;
    });
    const countdownDate = DateTime.fromJSDate(
      this.countdown().date! as Date
    ).set({
      hour: parseInt(this.countdown().time!.split(":")[0]),
      minute: parseInt(this.countdown().time!.split(":")[1]),
      second: 0,
    });

    const now = DateTime.now();
    const diff = countdownDate.diff(now, [
      "years",
      "months",
      "weeks",
      "days",
      "hours",
      "minutes",
      "seconds",
    ]);

    // If the countdown date is in the past, set the counter to 0
    if (diff.as("milliseconds") < 0) {
      this.countdown.update((value) => {
        value.counter = {
          years: 0,
          months: 0,
          weeks: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
        return value;
      });
      this.isPast = true;
      return;
    }

    this.countdown.update((value) => {
      value.counter = {
        years: diff.years,
        months: diff.months,
        weeks: diff.weeks,
        days: diff.days,
        hours: diff.hours,
        minutes: diff.minutes,
        seconds: +diff.seconds.toFixed(0),
      };
      return value;
    });

    if (isPlatformBrowser(this.platformId)) {
      this.changeDetectorRef.detectChanges();
    }
  }
}
