import { Component } from '@angular/core';
import { NavComponent } from '../components/nav/nav.component';
import { HeaderComponent } from '../components/header/header.component';
import { FeaturesComponent } from '../components/features/features.component';
import { DescriptionComponent } from '../components/description/description.component';
import { FooterComponent } from '../components/footer/footer.component';

@Component({
    selector: 'app-home',
    imports: [
        NavComponent,
        HeaderComponent,
        FeaturesComponent,
        DescriptionComponent,
        FooterComponent,
    ],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent {}
