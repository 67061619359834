import { Component, OnDestroy } from '@angular/core';
import { NavComponent } from '../components/nav/nav.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FooterComponent } from '../components/footer/footer.component';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-privacy',
    imports: [NavComponent, FooterComponent, TranslateModule],
    templateUrl: './privacy.component.html',
    styleUrl: './privacy.component.scss'
})
export class PrivacyComponent implements OnDestroy {
  constructor(private translate: TranslateService, private title: Title) {
    translate.get(['meta.title', 'footer.privacy']).subscribe((res: any) => {
      title.setTitle(res['footer.privacy'] + ' - ' + res['meta.title']);
    });
  }

  ngOnDestroy(): void {
    this.translate.get('meta.title').subscribe((res: string) => {
      this.title.setTitle(res);
    });
  }
}
