import { Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-description',
    imports: [TranslateModule, FontAwesomeModule],
    templateUrl: './description.component.html',
    styleUrl: './description.component.scss'
})
export class DescriptionComponent {
  appleIcon = faApple;
  androidIcon = faAndroid;
  desktopIcon = faDesktop;

  lang = environment.lang === 'de' ? 'de/' : '';
}
