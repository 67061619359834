import { Component, Inject, PLATFORM_ID, ViewChild } from "@angular/core";
import { faPlus, faShare } from "@fortawesome/free-solid-svg-icons";
import { environment } from "../../../environments/environment";
import { AddModalComponent } from "../add-modal/add-modal.component";
import { ShareModalComponent } from "../share-modal/share-modal.component";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { getWindow } from "ssr-window";

@Component({
  selector: "app-app-nav",
  imports: [
    FontAwesomeModule,
    TranslateModule,
    AddModalComponent,
    ShareModalComponent,
  ],
  templateUrl: "./app-nav.component.html",
  styleUrl: "./app-nav.component.scss",
})
export class AppNavComponent {
  plusIcon = faPlus;
  shareIcon = faShare;

  currentLang = environment.lang;

  bootstrap: any;

  @ViewChild(AddModalComponent) addModal: AddModalComponent | undefined;
  @ViewChild(ShareModalComponent) shareModal: ShareModalComponent | undefined;

  window = getWindow();

  constructor(
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {}

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.bootstrap = await import("bootstrap");
      this.window.addEventListener("scroll", () => {
        const nav = this.document.querySelector("nav");
        nav?.classList.toggle("bg-black", this.window.scrollY > 300);
      });
    }
    this.currentLang = this.translate.currentLang;
  }

  openNav() {
    // add class open to #mySidenav and #overlay
    this.document.getElementById("mySidenav")?.classList.add("open");
    this.document.getElementById("overlay")?.classList.add("open");
  }

  closeNav() {
    // remove class open from #mySidenav and #overlay
    this.document.getElementById("mySidenav")?.classList.remove("open");
    this.document.getElementById("overlay")?.classList.remove("open");
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("lang", lang);
    }
    this.closeNav();
  }

  openAddCountdown() {
    if (this.addModal?.newCountdown) {
      this.addModal.newCountdownModal = new this.bootstrap.Modal(
        this.addModal?.newCountdown.nativeElement
      );
      this.addModal?.newCountdownModal?.show();
    }
  }

  openShareCountdown() {
    if (this.shareModal?.shareCountdown) {
      this.shareModal.shareCountdownModal = new this.bootstrap.Modal(
        this.shareModal?.shareCountdown.nativeElement
      );
      this.shareModal?.shareCountdownModal?.show();
    }
  }
}
