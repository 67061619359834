import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Observer } from "rxjs";

export interface ProfileImage {
  small: string;
  medium: string;
  large: string;
}

export interface Links {
  self: string;
  html: string;
  photos: string;
  likes: string;
}

export interface User {
  id: string;
  username: string;
  name: string;
  first_name: string;
  last_name: string;
  instagram_username: string;
  twitter_username: string;
  portfolio_url: string;
  profile_image: ProfileImage;
  links: Links;
}

export interface Urls {
  raw: string;
  full: string;
  regular: string;
  small: string;
  thumb: string;
}

export interface Links2 {
  self: string;
  html: string;
  download: string;
}

export interface Result {
  id: string;
  created_at: Date;
  width: number;
  height: number;
  color: string;
  likes: number;
  liked_by_user: boolean;
  description: string;
  user: User;
  current_user_collections: any[];
  urls: Urls;
  links: Links2;
}

export interface UnsplashData {
  total: number;
  total_pages: number;
  results: Result[];
}

export interface Translation {
  translation: string;
}

export interface TranslateReturn {
  translations?: Translation[];
  word_count?: number;
  character_count?: number;
  detected_language?: string;
  detected_language_confidence?: number;
  code?: number;
  error?: string;
}

@Injectable({
  providedIn: "root",
})
export class UnsplashService {
  http = inject(HttpClient);
  translate = inject(TranslateService);

  async getPhotos(page: number, term: string) {
    let searchTerm = await this.translateString(term);
    if (!searchTerm || searchTerm === "") {
      searchTerm = term;
    }

    const params = new HttpParams()
      .set("page", page.toString())
      .set("query", searchTerm)
      .set("per_page", "12")
      .set("orientation", "landscape");

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization:
          "Client-ID 9dc450fbf912777274f65d1d239b6c49abf37426307033f5345e46edad0461bb",
      }),
      params,
    };

    return this.http.get("https://api.unsplash.com/search/photos", httpOptions);
  }

  public async translateString(text: string): Promise<string> {
    const promise = new Promise<string>(async (resolve, _reject) => {
      const apiURL = "https://translate.urlaubscountdown.de";
      const browserLang = this.translate.getBrowserLang() ?? "en";

      const params = new HttpParams()
        .set("text", text.toString())
        .set("source", browserLang);

      this.http
        .get(apiURL, { params })
        .pipe()
        .subscribe({
          next: (res: any) => {
            if (res?.translation) {
              resolve(res.translation);
            } else {
              resolve(text);
            }
          },
          error: () => resolve(text),
        });
    });
    return promise;
  }

  getFileImageFromURL(url: string, id: string) {
    this.triggerDownload(id).subscribe();
    return new Observable((observer: Observer<File>) => {
      // create an image object
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          // get file
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.height = img.height;
          canvas.width = img.width;
          if (ctx) {
            ctx.drawImage(img, 0, 0);
          }
          const dataURL = canvas.toDataURL("image/jpeg", 0.8); // compressing image to 80% quality
          const file = this.dataURLtoFile(dataURL, "image.jpg");
          observer.next(file);
          observer.complete();
        };
        img.onerror = (err) => {
          console.error(err);
          observer.error(err);
        };
      } else {
        // get file
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.height = img.height;
        canvas.width = img.width;
        if (ctx) {
          ctx.drawImage(img, 0, 0);
        }
        const dataURL = canvas.toDataURL("image/jpeg", 0.8); // compressing image to 80% quality
        const file = this.dataURLtoFile(dataURL, "image.jpg");
        observer.next(file);
        observer.complete();
      }
    });
  }

  triggerDownload(id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization:
          "Client-ID 9dc450fbf912777274f65d1d239b6c49abf37426307033f5345e46edad0461bb",
      }),
    };

    return this.http.get(
      "https://api.unsplash.com/photos/" + id + "/download",
      httpOptions
    );
  }

  dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/) ?? [""];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {
      type: mime[1] || "application/octet-stream",
    });
  }
}
