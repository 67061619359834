import { NgClass } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-footer',
    imports: [FontAwesomeModule, TranslateModule, NgClass],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
  @Input() isHome: boolean = false;
  translate = inject(TranslateService);
  lang = environment.lang;

  heartIcon = faHeart;

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
  }
}
