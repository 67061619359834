import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { Countdown } from "../model/countdown.modal";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CountdownService {
  countdown: WritableSignal<Countdown> = signal({
    title: "Der nächste Urlaub kommt",
    date: new Date("2025-12-28"),
    time: "00:00",
    image: 1,
    init: true,
  });

  http = inject(HttpClient);

  constructor(private translate: TranslateService) {
    this.getTranslateTitle();
    // countdown date is 35 days in the future
    this.countdown.update((value: Countdown) => {
      value.date = new Date(Date.now() + 35 * 24 * 60 * 60 * 1000);
      return value;
    });
  }

  async ngOnInit(): Promise<void> {
    this.getTranslateTitle();
  }

  async getTranslateTitle() {
    const title = await this.translate.instant("webapp.default");
    this.countdown.update((value: Countdown) => {
      value.title = title;
      return value;
    });
  }

  private url = environment.api + "countdown";

  async getCountdown(shortUrl: string) {
    const source = this.http.get<Countdown>(`${this.url}/${shortUrl}`);
    return await firstValueFrom(source);
  }

  createCountdown(countdown: Countdown) {
    const formData = new FormData();
    let entries = Object.entries(countdown as Countdown);
    entries.forEach((entry: any) => {
      formData.append(entry[0], entry[1] ?? "");
    });
    const source = this.http.post<Countdown>(this.url, formData);
    return firstValueFrom(source);
  }

  updateCountdown(countdown: Countdown) {
    if (!countdown.id) {
      return;
    }
    const formData = new FormData();
    let entries = Object.entries(countdown as Countdown);
    entries.forEach((entry: any) => {
      formData.append(entry[0], entry[1] ?? "");
    });
    const source = this.http.put<Countdown>(
      `${this.url}/${countdown.id}`,
      formData
    );
    return firstValueFrom(source);
  }
}
